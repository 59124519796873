<template>
    <modal
        class="suggest-topic-thanks"
        :adaptive="true"
        name="suggest-topic-thanks"
        width="600"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('suggest-topic-thanks')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="suggest-topic-thanks-container">
            <h1 class="title">
                Suggest Topic
            </h1>
            <topic-link :topic="suggestedTopic" />
            <h1 class="h0">
                <i>Thank you  for your suggestion</i>
            </h1>
            <p>Your suggested topic will be review<br>and a notification message will be send<br>as soon as possible.</p>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary next-button"
                    @click="$modal.hide('suggest-topic-thanks')"
                >
                    Ok
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "SuggestTopicThanks",
    components: {
        TopicLink: () => import(/* webpackChunkName: "tag" */ "@c/atoms/topic")
    },
    data() {
        return {
            suggestedTopic: null
        };
    },
    methods: {
        beforeOpen(event) {
            if (event.params?.suggestedTopic) {
                this.suggestedTopic = event.params.suggestedTopic;
            } else {
                event.cancel();
            }
        },
        beforeClose() {
            this.suggestedTopic = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.suggest-topic-thanks {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 40px;
        top: 40px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal {
        background-color: #0F0F10;
        border-radius: 10px;
        box-shadow: none;
        padding: 40px;
        overflow: visible;

        .suggest-topic-thanks-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .title {
                text-align: center;
                margin-bottom: 40px;
            }

            .topic {
                align-self: center;
                margin-bottom: 25px;
                pointer-events: none;
            }

            .h0 {
                font-family: 'Zilla Slab', serif;
                font-size: 36px;
                text-align: center;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
}
</style>
